import { SEARCH_RESULTS } from './constants';

const checkElementMatch = (element) =>
  element?.matchLevel !== SEARCH_RESULTS.matchLevelNone && element?.value;

const checkDescriptionAndTitle = (description, title) =>
  (description && checkElementMatch(description)) || (title && checkElementMatch(title));

const checkInstructions = (instructions) =>
  instructions?.find(({ value: { matchLevel } }) => matchLevel !== SEARCH_RESULTS.matchLevelNone)
    ?.value?.value;

const checkIngredientsList = (ingredientsList) =>
  ingredientsList
    ?.map(({ description, title }) => checkDescriptionAndTitle(description, title))
    .find((ingredientsListDescription) => ingredientsListDescription);

const checkIngredients = ({ sectionTitle, ingredientsList }) =>
  checkElementMatch(sectionTitle) || checkIngredientsList(ingredientsList);

const checkFaqItems = (faqItems) =>
  faqItems
    .map(({ question, answer }) => checkDescriptionAndTitle(question, answer))
    .find((faqItemDescription) => faqItemDescription);

const checkFaq = (faq) =>
  faq
    .map(({ title, faqItems }) => checkElementMatch(title) || checkFaqItems(faqItems))
    .find((faqDescription) => faqDescription);

const checkTextWithMedia = ({ title, description, text, disclaimer }) =>
  (description && checkElementMatch(description)) ||
  (text && checkElementMatch(text)) ||
  (disclaimer && checkElementMatch(disclaimer)) ||
  (title && checkElementMatch(title));

const checkArticleSection = ({ title, description, instruction }) =>
  (description && checkElementMatch(description)) ||
  (instruction && instruction[0] && checkInstructions(instruction[0].instructions)) ||
  (title && checkElementMatch(title));

const checkBody = (body) =>
  body
    .map(
      ({
        title,
        description,
        instruction,
        textWithImage,
        textbox,
        ingredients,
        textWithVideo,
        faq,
        articleSection,
      }) =>
        checkDescriptionAndTitle(description, title) ||
        (instruction && instruction[0] && checkInstructions(instruction[0].instructions)) ||
        (textWithImage && checkTextWithMedia(textWithImage)) ||
        (textWithVideo && checkTextWithMedia(textWithVideo)) ||
        (textbox && checkDescriptionAndTitle(textbox.text, textbox.title)) ||
        (ingredients && checkIngredients(ingredients)) ||
        (faq && checkFaq(faq)) ||
        (articleSection && checkArticleSection(articleSection))
    )
    .find((bodyDescription) => bodyDescription);

const prepareDescription = (
  { pageDescription, bannerDescription, body, ldsBody, faq },
  fallbackValue
) =>
  (pageDescription && checkElementMatch(pageDescription)) ||
  (bannerDescription && checkElementMatch(bannerDescription)) ||
  (body && checkBody(body)) ||
  (ldsBody && checkElementMatch(ldsBody)) ||
  (faq && checkFaq(faq)) ||
  fallbackValue;

const prepareSearchResults = (hits) =>
  hits.map(({ objectID, url, _snippetResult: { content, title: { value } }, seoDescription }) => ({
    objectID,
    title: value,
    url,
    description: prepareDescription(content, seoDescription),
  }));

export default prepareSearchResults;
