const createBreadcrumbs = (crumbs, areBreadcrumbsDisplayed, nodes, pathname) => {
  if (!areBreadcrumbsDisplayed) {
    return [];
  }

  if (pathname === '/') {
    return crumbs;
  }

  return crumbs.map((item) => {
    const contextData = nodes.find((node) => node?.context?.breadcrumb?.location === item.pathname);

    if (contextData && contextData.context.breadcrumbName) {
      item.crumbLabel = contextData.context.breadcrumbName;
    } else {
      const preparedLabel = item.crumbLabel.replace(/-/g, ' ');
      item.crumbLabel = preparedLabel;
    }

    return item;
  });
};

export default createBreadcrumbs;
