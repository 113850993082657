import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { getLocationQS } from 'utils/browser';

import SimpleSearch from '../SimpleSearch';
import { SearchBannerProps } from './models';

import './SearchBanner.scss';

const SearchBanner: FC<SearchBannerProps> = ({ searchResultsPageTitle, langProps }) => (
  <Row data-testid="search banner" className="search-banner">
    <Col className="gradient-container">
      <Row>
        <Col xs={12}>
          <h1 className="search-banner__title">{searchResultsPageTitle}</h1>
        </Col>
        <Col lg={6}>
          <SimpleSearch {...{ value: getLocationQS(), langProps }} />
        </Col>
      </Row>
    </Col>
  </Row>
);

export default SearchBanner;
