const getSearchResultsMessage = (
  searchResultsLength: number,
  singularResultsMessage: string,
  pluralResultsMessage: string
): string =>
  `${searchResultsLength} ${
    searchResultsLength > 1 ? pluralResultsMessage : singularResultsMessage
  }`;

export default getSearchResultsMessage;
