import React, { FC, useEffect, useState } from 'react';

import Button, { VisualType, VisualVariant } from 'common/Button';
import { StainsListing } from 'components/Listing';

import { SearchResultsProps } from './models';
import SearchResultsHeader from './SearchResultsHeader';
import SearchResultsItem from './SearchResultsItem';

import './searchResults.scss';

const SearchResults: FC<SearchResultsProps> = ({
  button,
  resultsCount,
  noResultsDescription,
  singularResultsMessage,
  pluralResultsMessage,
  noResultsMessage,
  stainsCardsListing,
  results,
  query,
}) => {
  const [searchResultsCount, setSearchResultsCount] = useState(resultsCount);
  const resultsLength = results.length;
  const { text, ariaLabel, type } = button[0];

  useEffect(() => {
    setSearchResultsCount(resultsCount);
  }, [query]);

  return (
    <section className="search-results" data-testid="search-results">
      <SearchResultsHeader
        {...{
          resultsLength,
          singularResultsMessage,
          pluralResultsMessage,
          noResultsMessage,
          query,
        }}
      />
      {resultsLength ? (
        <>
          <ul className="search-results__list">
            {results.slice(0, searchResultsCount).map(({ title, url, description }) => (
              <SearchResultsItem key={url} {...{ title, url, description }} />
            ))}
          </ul>
          {resultsLength > searchResultsCount ? (
            <div className="search-results__btn">
              <Button
                visualType={type[0] as VisualType}
                visualVariant={VisualVariant.Dark}
                ariaLabel={ariaLabel}
                onClick={() => setSearchResultsCount(resultsCount + searchResultsCount)}
              >
                {text}
              </Button>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <p className="search-results__no-results-message">{noResultsDescription}</p>
          <StainsListing {...stainsCardsListing} />
        </>
      )}
    </section>
  );
};

export default SearchResults;
