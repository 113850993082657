import React, { FC, useEffect, useState } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import { getLocationQS } from 'utils/browser';
import { KEYBOARD_KEYS } from 'utils/constants';

import { SimpleSearchProps, SimpleSearchQuery } from './models';

import './SimpleSearch.scss';

const SimpleSearch: FC<SimpleSearchProps> = ({ value, langProps }) => {
  const {
    allSharedComponentsSettings: { nodes: sharedComponentsSettingsNodes },
  } = useStaticQuery<SimpleSearchQuery>(graphql`
    {
      allSharedComponentsSettings {
        nodes {
          lang
          simpleSearch {
            clearAriaLabel
            inputPlaceholderLabel
            searchAriaLabel
            title
            searchResultPageLocation {
              url
            }
          }
        }
      }
    }
  `);

  const {
    simpleSearch: {
      inputPlaceholderLabel,
      searchAriaLabel,
      clearAriaLabel,
      searchResultPageLocation,
    },
  } = sharedComponentsSettingsNodes.find(({ lang }) => lang === langProps.lang)!;

  const [inputValue, setInputValue] = useState(value || '');
  const query = getLocationQS() || '';

  useEffect(() => {
    setInputValue(query);
  }, [query]);

  const handleSubmit = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    inputValue && navigate(`${searchResultPageLocation[0].url}?q=${inputValue}`);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleClearClick = () => {
    setInputValue('');
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KEYBOARD_KEYS.enter) {
      handleSubmit(event);
    }
  };

  return (
    <div className="simple-search" data-testid="simple-search">
      <div className="simple-search__search">
        <button
          className="simple-search__search-button"
          type="button"
          aria-label={searchAriaLabel}
          onClick={handleSubmit}
          data-testid="simple-search-search-button"
        />

        <label className="simple-search__label" htmlFor="simple-search">
          <span className="visually-hidden">{searchAriaLabel}</span>
        </label>
        <input
          className="simple-search__input"
          placeholder={inputPlaceholderLabel}
          aria-label={searchAriaLabel}
          value={inputValue}
          onChange={onInputChange}
          onKeyDown={handleKeyPress}
          data-testid="simple-search-input"
          type="search"
          id="simple-search"
          inputMode="search"
        />

        <button
          className="simple-search__clear-button"
          type="button"
          aria-label={clearAriaLabel}
          onClick={handleClearClick}
          data-testid="simple-search-clear-button"
        />
      </div>
    </div>
  );
};

export default SimpleSearch;
