import React, { FC } from 'react';

import getSearchResultsMessage from 'utils/getSearchResultsMessage';

import { SearchResultsHeaderProps } from './models';

const SearchResultsHeader: FC<SearchResultsHeaderProps> = ({
  resultsLength,
  singularResultsMessage,
  pluralResultsMessage,
  noResultsMessage,
  query,
}) => (
  <h2 className="search-results__header" data-testid="search-results-header">
    {resultsLength
      ? getSearchResultsMessage(resultsLength, singularResultsMessage, pluralResultsMessage)
      : noResultsMessage}
    <span className="search-results__hit">{query}</span>
  </h2>
);

export default SearchResultsHeader;
