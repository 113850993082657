import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import algoliasearch from 'algoliasearch/lite';

import Layout from 'layout/Layout';
import Loader from 'common/Loader';
import SearchBanner from 'components/SearchBanner';
import SearchResults from 'components/SearchResults';
import Teaser from 'components/Teaser';
import { getLocationQS } from 'utils/browser';
import createBreadcrumbs from 'utils/createBreadcrumbs';
import { gtmService } from 'utils/gtmService';
import prepareSearchResults from 'utils/prepareSearchResults';

import { SearchResultsProps } from './models';

const SearchResultsPage: FC<SearchResultsProps> = ({
  data: {
    searchResultsPage: {
      seo,
      langProps,
      bottomTeaser,
      searchResultsPageTitle,
      searchResults,
      stainsCards: [stainsCardsListing],
    },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const algoliaClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID!,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY!
  );
  const indexGlobal = algoliaClient.initIndex(`${process.env.GATSBY_ALGOLIA_INDEX}--new`);
  const query = getLocationQS() || '';
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);

  const searchResultsProps = {
    ...searchResults,
    results,
    query,
    stainsCardsListing,
  };

  useEffect(() => {
    const timeoutId = gtmService.emitSearchResultsView(query);
    setLoading(true);

    indexGlobal
      .search(query, {
        filters: `lang:"${langProps.lang}"`,
      })
      .then(({ hits }) => (query ? setResults(prepareSearchResults(hits)) : setResults([])))
      .catch((e) => console.error(e))
      .finally(() => {
        setLoading(false);
      });

    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [query]);

  return (
    <Layout {...{ seo, langProps, breadcrumbs, pagePathname }}>
      <SearchBanner {...{ searchResultsPageTitle, langProps }} />
      {loading ? <Loader /> : query ? <SearchResults {...searchResultsProps} /> : null}
      {bottomTeaser ? <Teaser {...bottomTeaser} /> : null}
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    searchResultsPage(link: { eq: $link }) {
      seo {
        ...SEOStructureFragment
      }
      searchResultsPageTitle
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      bottomTeaser {
        ...TeaserFragment
      }
      searchResults {
        ...SearchResultsFragment
      }
      stainsCards {
        ...ListingFragment
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default SearchResultsPage;
