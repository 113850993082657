import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import { VisualType, VisualVariant } from 'common/Button';
import LinkButton from 'components/LinkButton';

import { SearchResultsItemProps } from './models';

const SearchResultsItem: FC<SearchResultsItemProps> = ({ title, url, description }) => (
  <li className="search-results__list-item" data-testid="search-results-item">
    <div className="search-results__discover">
      <LinkButton
        {...{
          text: title,
          url,
          variant: VisualVariant.Darker,
          type: VisualType.Discover,
        }}
      />
    </div>
    <DangerouslySetInnerHtml
      html={description}
      element="p"
      className="search-results__description"
    />
  </li>
);

export default SearchResultsItem;
